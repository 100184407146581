import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {TokenService} from "../../../services/auth/token.service";
import {ProductionReceiptsService} from "../data/production-receipts.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {PlantsService} from "../../company/data/plants.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Subscription} from "rxjs";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {
    JBMTableActionDef,
    JBMTableColumnAlignment,
    JBMTableColumnDef,
    JBMTableColumnType,
    JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";

@Component({
  selector: 'synchronise-receipts',
  templateUrl: './synchronise-receipts.component.html',
  styleUrls: ['./synchronise-receipts.component.scss']
})
export class SynchroniseReceiptsComponent implements OnInit, OnDestroy {
  rights: GroupRights;
  dropReceiptRights: GroupRights;
  language: string;
  receipts: any[]=[];
  plantOptions: JBMSelectOption[]=[];
  plants_id: number=0;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  ids: number[]=[];
  id: number
  receipt_id: number
  selected_receipts: any[]=[];

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible = true;
  search = '';
  filtersVisible = false;
  loading = false;

  // Date range filtering
  fromDate: number=0;
  toDate: number=0;

  count$: Subscription;

  @ViewChild('table') table: JBMTableComponent;

  @ViewChild('plantTemplate') plantTemplate: TemplateRef<any>;
  @ViewChild('dropTemplate') dropTemplate: TemplateRef<any>;
  @ViewChild('checkTemplate') checkTemplate: TemplateRef<any>;
  @ViewChild('truckTemplate') truckTemplate: TemplateRef<any>;
  @ViewChild('modalSyncOrder') modalSyncOrder: ElementRef;
  @ViewChild('modalDrop') modalDrop: ElementRef;

  constructor(
      private Router: Router,
      private TokenService: TokenService,
      private ProductionReceiptsService: ProductionReceiptsService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService,
      private PlantsService: PlantsService,
      private TranslateService: TranslateService
  ) {
    this.language=this.TokenService.getLanguage();

    this.rights=this.TokenService.getRightsByName('production');
    this.dropReceiptRights=this.TokenService.getRightsByName('drop_receipts');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'check', type: JBMTableColumnType.string, iconClassname: 'fa fa-check', width: 3 },
      { name: 'receipts_id', type: JBMTableColumnType.number, align: JBMTableColumnAlignment.left,
        header: this.TranslateService.GetTranslation('production.receipt') + ' ' + this.TranslateService.GetTranslation('ui.id'),
        sortable: true, filterable: true, width: 5 },
      { name: 'plant', type: JBMTableColumnType.number, align: JBMTableColumnAlignment.left,
        header: this.TranslateService.GetTranslation('company.plant'), width: 15 },
      { name: 'driver', header: this.TranslateService.GetTranslation('production.truck'),
        sortable: true, filterable: true, width: 14 },
      { name: 'execute_date', type: JBMTableColumnType.date, header: this.TranslateService.GetTranslation('datetime.date'),
        sortable: true, width: 5 },
      { name: 'loading_time', type: JBMTableColumnType.time, header: this.TranslateService.GetTranslation('datetime.start'), iconClassname: 'far fa-clock', width: 3.5 },
      { name: 'leave_plant_time', type: JBMTableColumnType.time, header: this.TranslateService.GetTranslation('datetime.stop'), iconClassname: 'far fa-clock', width: 3.5 },
      { name: 'receipt_amount', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.produced'), align: JBMTableColumnAlignment.right,
        sortable: true, filterable: true, width: 7, className: 'pr-3' },
      { name: 'dummy', type: JBMTableColumnType.string },
      { name: 'details', type: JBMTableColumnType.panelToggle, header: this.TranslateService.GetTranslation('ui.details'), width: 6 },
      { name: 'drop', width: 4 }
    ];
    this.queryHttpHelper = new QueryHttpHelper();

    this.queryHttpHelper.addAliasedColumn('id','r.id');
    this.queryHttpHelper.addAliasedColumn('receipts_id','r.receipts_id');
    this.queryHttpHelper.addAliasedColumn('loading_time','r.loading_time');
    this.queryHttpHelper.addAliasedColumn('leave_plant_time','r.leave_plant_time');
    this.queryHttpHelper.addAliasedColumn('driver','d.name');

    this.queryHttpHelper.setSortColumn('receipts_id',true);
    this.queryHttpHelper.addSearchColumn('receipts_id', SearchColumnType.number);
    this.queryHttpHelper.addSearchColumn('receipt_amount', SearchColumnType.number);
    this.queryHttpHelper.addSearchColumn('driver', SearchColumnType.string);
  }
  ngOnInit(): void {
    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          if(plants.length>1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
            this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          }

          this.columnDefs[1].template = this.checkTemplate;
          this.columnDefs[3].template = this.plantTemplate;
          this.columnDefs[4].template = this.truckTemplate;
          this.columnDefs[11].template = this.dropTemplate;

          this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
          this.getData(true);
        }
    );
  }
  getData(countRows:boolean=false) {
    this.loading = true;
    this.receipts=[];

    // Reset multi selection
    this.ids=[];

    let params=this.setParams();
    this.ProductionReceiptsService.getUnsynchronised(params).subscribe(
        (data)=>this.receipts=data.data,
        (error)=>console.error(error),
        ()=> {
          this.loading=false;
          this.formatData();
          if(countRows) {
            let params=this.setParams(true);
            this.count$=this.ProductionReceiptsService.getUnsynchronised(params).subscribe(
                (data: any)=>this.queryHttpHelper.paginationProps.itemCount=data.data,(error)=>console.error(error) );
          }
        }
    )
  }
  setParams(countRows=false) {
      let params=this.queryHttpHelper.getHttpParams(false);
      if(this.plants_id) params=params.append('plants_id', this.plants_id.toString());
      if(this.fromDate) {
          params=params.append('fromdate', this.fromDate.toString());
          params=params.append('todate', this.toDate.toString());
      }
      params=params.append('check_production_lines','1');
      if(countRows)
          params=params.append('count','1');

      return params;
  }
  formatData() {
    for(let receipt of this.receipts) {
        receipt.isExpandable=receipt.has_production_lines;
        receipt.receipt_amount=receipt.receipt_amount.toString().replace('.',',') + ' ' + this.TranslateService.GetTranslation('quantity.m3');
    }
  }
  rowCountChange(rowCount: number) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  changePlant(event: any) {
    this.plants_id=parseInt(event.target.value);

    this.queryHttpHelper.clearFilters();
    for(let c=0; c<this.table.columnDefs.length; c++)
        this.table.columnDefs[c].filterValue='';
    this.queryHttpHelper.setSearchValue('');

    this.getData(true);
  }
  setDateRange(event: any) {
    this.fromDate=event.fromUnixTime;
    this.toDate=event.toUnixTime;
    this.getData(true);
  }
  undoDateRange() {
    this.fromDate=0;
    this.toDate=0;
    this.getData(true);
  }

  // Event handling
  pageChange(page: number) {
      this.queryHttpHelper.paginationProps.page = page;
      this.getData();
  }
  searchChange(event: any) {
      let value = event.value;
      const minlength = event.minlength;
      value = value.length < minlength ? '' : value;
      if (this.queryHttpHelper.getSearchValue() !== value) {
          this.queryHttpHelper.setSearchValue(value);
          this.getData(true);
      }
  }
  sortChange(event: any) {
      this.queryHttpHelper.setSortColumn(event.name, event.descending);
      this.getData();
  }
  toggleFilters() {
      this.filtersVisible = !this.filtersVisible;
      this.queryHttpHelper.clearFilters();
      for(let c=0; c<this.table.columnDefs.length; c++)
          this.table.columnDefs[c].filterValue='';
      this.queryHttpHelper.setSearchValue('');
      this.getData(true);
  }
  filterChange(event) {
      // Delete receipt id number filter when another filter becomes active
      if(event.name!=='receipts_id') {
          this.queryHttpHelper.deleteFilterItem('receipts_id');
          this.table.resetColumnFilter('receipts_id');
      }
      // Delete other filters when receipt id number filter becomes active
      if(event.name==='receipts_id') {
          this.queryHttpHelper.clearFilters();
          this.table.resetColumnFilters();
      }

      this.queryHttpHelper.setFilterItem(event.name, event.value);
      this.getData(true);
  }
  rowSelect(row: any) {
      this.toggleReceipt(row.id);
  }
  toggleReceipt(receipt_id: number) {
    let index=this.ids.indexOf(receipt_id);
    if(index>-1)
      this.ids.splice(index,1);
    else
      this.ids.push(receipt_id);
  }
  drop(data: any) {
    this.id=data.id
    this.receipt_id=data.receipts_id
    this.ModalService.open(this.modalDrop,'dropReceipt')
  }
  successDrop() {
    this.ModalService.close('dropReceipt')

    // Reset multi selection
    this.ids=[];

    this.getData(true)
  }
  closeDrop() {
    this.ModalService.close('dropReceipt')
  }
  synchronise() {
    this.ModalService.open(this.modalSyncOrder,'synchronise')
  }
  resetSelection() {
      this.ids=[];
  }
  saveSyncOrder() {
    this.ModalService.close('synchronise');
    if(this.ids.length===1)
        this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    else
        this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entities-saved'));

    // Heads up! Server needs some time to process the synchronisation
    setTimeout(()=>this.getData(true),500)
  }
  closeSyncOrder() {
    this.ModalService.close('synchronise')
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
