import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {RecipesService} from "../data/recipes.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType,
  JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {HttpParams} from "@angular/common/http";
import {JBMSearchBoxComponent} from "../../../components/JBM/Views/JBMSearchBox/JBMSearchBox.component";
import {GroupRights} from '../../users/data/interfaces/grouprights';

@Component({
  selector: 'recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit, OnDestroy {
  userRights: GroupRights;
  items: any[];
  recipe: any={};
  environmentsFilter: string='';
  id: number=0;
  published: boolean=true;
  columnDefs: JBMTableColumnDef[];
  language: string=this.TokenService.getLanguage();
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  filtersVisible = false;
  search: string='';

  activeTab: number=0;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('searchBox') searchBox: JBMSearchBoxComponent;

  @ViewChild('environmentsTemplate') environmentsTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('publishedTemplate') publishedTemplate: TemplateRef<any>;
  @ViewChild('modalRecipe') modalRecipe: ElementRef;
  @ViewChild('modalArticles') modalArticles: ElementRef;

  constructor(
      private RecipesService: RecipesService,
      private ModalService: ModalService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('recipes');

    this.columnDefs=[
      { name: 'id1', type: JBMTableColumnType.id },
      { name: 'public_id', type: JBMTableColumnType.number, align: JBMTableColumnAlignment.left, header: this.TranslateService.GetTranslation('translation.key'), sortable: true, filterable: true, width: 5.5 },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true, width: 20 },
      { name: 'internal_description', header: this.TranslateService.GetTranslation('entity.notes'), sortable: true, filterable: true },
      { name: 'strength', header: this.TranslateService.GetTranslation('concrete.strength'), sortable: true, filterable: true, width: 8 },
      { name: 'consistency', header: this.TranslateService.GetTranslation('concrete.consistency'), sortable: true, filterable: true, width: 8 },
      { name: 'environment_descriptions', header: this.TranslateService.GetTranslation('financial.concrete-environments'), filterable: true, width: 15 },
      { name: 'published', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, header: this.TranslateService.GetTranslation('ui.visible'), width: 4 },
      { name: 'actions', type: JBMTableColumnType.boolean, width: 9 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.addAliasedColumn('public_id','r.public_id');
    this.queryHttpHelper.addAliasedColumn('description','r.description');
    this.queryHttpHelper.addAliasedColumn('internal_description','r.internal_description');
    this.queryHttpHelper.addAliasedColumn('strength','s.code');
    this.queryHttpHelper.addAliasedColumn('consistency','c.code');

    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('public_id', SearchColumnType.number);
    this.queryHttpHelper.addSearchColumn('internal_description', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('strength', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('consistency', SearchColumnType.string);

    setTimeout(()=> {
      this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
      this.getData(true);
    },100);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.setParams(false);
    this.items$=this.RecipesService.get(this.language, params).subscribe(
      (data: any) => this.items=data.data,(error)=> console.error(error),()=> {
        this.loading=false;

        // Format environments
        for(let recipe of this.items)
          recipe.environments=this.RecipesService.environmentsToString(recipe.environments);
        this.columnDefs[6].template = this.environmentsTemplate;

        // Set published template
        this.columnDefs[7].template = this.publishedTemplate;
        // Set custom actions template
        this.columnDefs[8].template = this.actionsTemplate;

        if(countRows) {
          let params=this.setParams(true);
          this.count$=this.RecipesService.get(this.language, params).subscribe(
              (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,
              (error)=>console.error(error));
        }
      } );
  }
  setParams(countRows:boolean=false) {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    params=this.setPublishedParam(params);
    if(this.environmentsFilter)
      params=params.append('environments', this.environmentsFilter)
    return params;
  }
  rowCountChange(rowCount: number) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    setTimeout(()=>this.getData(),1000);
  }
  setPublishedParam(params: HttpParams) {
    return params.append('published',this.published ? '1': '0');
  }
  togglePublished() {
    this.published=!this.published;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.searchBox.resetSearch();
    this.table.resetColumnFilterValues();
    this.getData(true);
  }
  pageChange(page: number) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.environmentsFilter=value;
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters() {
    this.filtersVisible = !this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.table.resetColumnFilterValues();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    // Delete ID number filter when another filter becomes active
    if(event.name!=='public_id') {
      this.queryHttpHelper.deleteFilterItem('public_id');
      this.table.resetColumnFilter('public_id');
    }
    // Delete other filters when ID number filter becomes active
    if(event.name==='public_id') {
      this.queryHttpHelper.clearFilters();
      this.table.resetColumnFilters();
    }

    if(event.name==='environment_descriptions') {
      this.environmentsFilter = event.value;
      this.queryHttpHelper.deleteFilterItem('environment_descriptions');
    } else
      this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  create() {
    this.id=0;
    this.ModalService.open(this.modalRecipe,'recipe');
  }
  rowSelect(row) {
    this.edit(row.id);
  }
  edit(id: number) {
    this.id=id;
    this.activeTab=0;
    this.ModalService.open(this.modalRecipe,'recipe');
  }
  recipeSuccess() {
    this.showToast();
    this.ModalService.close('recipe');
    this.getData(true);
  }
  recipeClose() {
    this.ModalService.close('recipe');
  }
  resources(id: number) {
    this.id=id;
    this.activeTab=1;
    this.ModalService.open(this.modalRecipe,'recipe');
  }
  editArticles(recipe: any) {
    this.recipe=recipe;
    this.ModalService.open(this.modalArticles,'recipe');
  }
  articlesSuccess() {
    this.showToast();
    this.ModalService.close('recipe');
  }
  articlesClose() {
    this.ModalService.close('recipe');
  }
  togglePublishedState(id: number, published: boolean) {
    this.RecipesService.setPublished(id, published).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.showToast();
          this.getData(true);
        }
    )
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
