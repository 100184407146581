import { Injectable } from '@angular/core';
import { HttpService} from "../http.service";
import { TranslateService } from "../multilingual/translate.service";
import { subjectTypes} from "./subjectTypes";

const baseUrl='log/';

@Injectable({
  providedIn: 'root'
})
export class LogSubjectService {

  constructor(
      private HttpService: HttpService,
      private TranslateService: TranslateService ) {}

  getData() {
    return this.HttpService.get(baseUrl+'read/types');
  }
  getTranslatedSubjectPlural(subject_type: number): string {
    switch(subject_type) {
      // Customers
      case subjectTypes.customers:
        return this.TranslateService.GetTranslation('entity.customers');
      case subjectTypes.customertypes:
        return this.TranslateService.GetTranslation('customer.types');
      // Projects
      case subjectTypes.projects:
        return this.TranslateService.GetTranslation('entity.projects');
      case subjectTypes.orders:
        return this.TranslateService.GetTranslation('project.orders');
      case subjectTypes.order_articles:
        return this.TranslateService.GetTranslation('order.articles');

      // Recipe
      case subjectTypes.recipes:
        return this.TranslateService.GetTranslation('entity.recipes');
      case subjectTypes.resources:
        return this.TranslateService.GetTranslation('entity.resources');

      // Planning
      case subjectTypes.planning:
        return this.getTranslatedSubjectSingular(subject_type);

      // Production
      case subjectTypes.drop_receipts:
        return this.TranslateService.GetTranslation('menu.production') +
          this.TranslateService.GetTranslation('production.receipts').toLowerCase();

      // Invoicing
      case subjectTypes.invoices:
        return this.TranslateService.GetTranslation('invoicing.invoices');

      // Quotations
      case subjectTypes.quotations:
        return this.TranslateService.GetTranslation('menu.quotations');

      // Contacts
      case subjectTypes.contacts:
        return this.TranslateService.GetTranslation('entity.contacts');

      // Settings
      case subjectTypes.settings:
        return this.getTranslatedSubjectSingular(subject_type);

      // Financial
      case subjectTypes.articlegroups:
        return this.TranslateService.GetTranslation('financial.articlegroups');
      case subjectTypes.articles:
        return this.TranslateService.GetTranslation('financial.articles');
      case subjectTypes.article_price_types:
        return this.TranslateService.GetTranslation('financial.article-price-types');
      case subjectTypes.article_prices:
        return this.TranslateService.GetTranslation('financial.article-prices');
      case subjectTypes.article_pricelists:
        return this.TranslateService.GetTranslation('financial.article-pricelists');
      case subjectTypes.customer_article_pricelists:
        return this.TranslateService.GetTranslation('entity.customer') + ' ' +
          this.TranslateService.GetTranslation('financial.article-pricelists').toLowerCase();
      case subjectTypes.environments:
        return this.TranslateService.GetTranslation('financial.concrete-environments');
      case subjectTypes.strengths:
        return this.TranslateService.GetTranslation('financial.concrete-strengths');
      case subjectTypes.consistencies:
        return this.TranslateService.GetTranslation('financial.concrete-consistencies');
      case subjectTypes.concrete_prices:
        return this.TranslateService.GetTranslation('financial.concrete-prices');
      case subjectTypes.concrete_pricelists:
        return this.TranslateService.GetTranslation('financial.concrete-pricelists');
      case subjectTypes.customer_concrete_pricelists:
        return this.TranslateService.GetTranslation('entity.customer') + ' ' +
          this.TranslateService.GetTranslation('financial.concrete-pricelists').toLowerCase();
      case subjectTypes.resource_pricelists:
        return this.TranslateService.GetTranslation('entity.resource') + ' ' +
          this.TranslateService.GetTranslation('financial.pricelists').toLowerCase();
      case subjectTypes.resource_prices:
        return this.TranslateService.GetTranslation('entity.resource') +
          this.TranslateService.GetTranslation('financial.prices').toLowerCase();

      // Presentations
      case subjectTypes.presentations:
        return this.TranslateService.GetTranslation('menu.presentations');
      case subjectTypes.text_placeholders:
        return this.TranslateService.GetTranslation('presentations.text-placeholder');
      case subjectTypes.media_files:
        return this.TranslateService.GetTranslation('presentations.mediafiles');
      case subjectTypes.mail_settings: return this.TranslateService.GetTranslation('mail.server') + ' ' +
          this.TranslateService.GetTranslation('entity.settings').toLowerCase();

      // Users and usergroups
      case subjectTypes.usergroups:
        return this.TranslateService.GetTranslation('users.usergroups');
      case subjectTypes.users:
        return this.TranslateService.GetTranslation('entity.users');

      // Company and plants
      case subjectTypes.company:
        return this.TranslateService.GetTranslation('company');
      case subjectTypes.plants:
        return this.TranslateService.GetTranslation('company.plants');

      // System
      case subjectTypes.translations:
        return this.TranslateService.GetTranslation('entity.translations');
      default:
        return 'Unknown log subjecttype';
    }
  }
  getTranslatedSubjectSingular(subjectType: number): string {
    switch(subjectType) {
      // Customers
      case subjectTypes.customertypes:
        return this.TranslateService.GetTranslation('customer.type');
      case subjectTypes.customers:
        return this.TranslateService.GetTranslation('entity.customer');

      // Projects
      case subjectTypes.projects:
        return this.TranslateService.GetTranslation('entity.project');
      case subjectTypes.orders:
        return this.TranslateService.GetTranslation('project.order');
      case subjectTypes.order_articles:
        return this.TranslateService.GetTranslation('order.article');

      // Recipe
      case subjectTypes.recipes:
        return this.TranslateService.GetTranslation('entity.recipe');
      case subjectTypes.resources:
        return this.TranslateService.GetTranslation('entity.resource');

      // Planning
      case subjectTypes.planning:
        return this.TranslateService.GetTranslation('entity.planning');

        // Production
      case subjectTypes.drop_receipts:
        return this.TranslateService.GetTranslation('menu.production') +
          this.TranslateService.GetTranslation('production.receipt').toLowerCase();

      // Invoicing
      case subjectTypes.invoices:
        return this.TranslateService.GetTranslation('invoicing.invoice');

      // Quotations
      case subjectTypes.quotations:
        return this.TranslateService.GetTranslation('entity.quotation');

      // Contacts
      case subjectTypes.contacts:
        return this.TranslateService.GetTranslation('entity.contact');

      // Settings
      case subjectTypes.settings:
        return this.TranslateService.GetTranslation('entity.settings');

      // Financial
      case subjectTypes.articlegroups:
        return this.TranslateService.GetTranslation('financial.articlegroup');
      case subjectTypes.articles:
        return this.TranslateService.GetTranslation('financial.article');
      case subjectTypes.article_price_types:
        return this.TranslateService.GetTranslation('financial.article-price-type');
      case subjectTypes.article_prices:
        return this.TranslateService.GetTranslation('financial.article-price');
      case subjectTypes.article_pricelists:
        return this.TranslateService.GetTranslation('financial.article-pricelist');
      case subjectTypes.customer_article_pricelists:
        return this.TranslateService.GetTranslation('entity.customer') + ' ' +
          this.TranslateService.GetTranslation('financial.article-pricelist').toLowerCase();
      case subjectTypes.environments:
        return this.TranslateService.GetTranslation('concrete.environment');
      case subjectTypes.strengths:
        return this.TranslateService.GetTranslation('concrete.strength');
      case subjectTypes.consistencies:
        return this.TranslateService.GetTranslation('concrete.consistency');
      case subjectTypes.concrete_prices:
        return this.TranslateService.GetTranslation('financial.concrete-price');
      case subjectTypes.concrete_pricelists:
        return this.TranslateService.GetTranslation('financial.concrete-pricelist');
      case subjectTypes.customer_concrete_pricelists:
        return this.TranslateService.GetTranslation('entity.customer') + ' ' +
          this.TranslateService.GetTranslation('financial.concrete-pricelist').toLowerCase();
      case subjectTypes.resource_pricelists:
        return this.TranslateService.GetTranslation('entity.resources') + ' ' +
          this.TranslateService.GetTranslation('financial.pricelist').toLowerCase();
      case subjectTypes.resource_prices:
        return this.TranslateService.GetTranslation('entity.resource') +
          this.TranslateService.GetTranslation('financial.price').toLowerCase();

      // Presentations
      case subjectTypes.presentations:
        return this.TranslateService.GetTranslation('entity.presentation');
      case subjectTypes.text_placeholders:
        return this.TranslateService.GetTranslation('presentations.text-placeholder');
      case subjectTypes.media_files:
        return this.TranslateService.GetTranslation('presentations.mediafile');
      case subjectTypes.mail_settings:
        return this.TranslateService.GetTranslation('mail.server') + ' ' +
          this.TranslateService.GetTranslation('entity.settings').toLowerCase();

      // Users and usergroups
      case subjectTypes.usergroups:
        return this.TranslateService.GetTranslation('entity.usergroup');
      case subjectTypes.users:
        return this.TranslateService.GetTranslation('entity.user');

      // Company and plants
      case subjectTypes.company:
        return this.TranslateService.GetTranslation('company');
      case subjectTypes.plants:
        return this.TranslateService.GetTranslation('company.plant');

      // System
      case subjectTypes.translations:
        return this.TranslateService.GetTranslation('entity.translation');
      default:
          return 'Unknown log subjecttype';
    }
  }
  asKeyValueArray(plural: boolean=true) {
    let keyValues=[];
    for(const [value, key] of Object.entries(subjectTypes)) {
      let valueStr='';
      if(plural)
        valueStr=this.getTranslatedSubjectPlural(key);
      else
        valueStr=this.getTranslatedSubjectSingular(key);
      keyValues.push({key: key, value: valueStr});
    }
    return keyValues;
  }
}
