<form [formGroup]="form" (ngSubmit)="save()" class="drop">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'production.drop-receipt'|translate}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-static-label [labelCaption]="('production.receipt'|translate) +' '+ ('ui.id'|translate)" [value]="receipt_id.toString()"></jbm-static-label>
      <jbm-textarea [id]="'reason'" [formControl]="formControls.reason" labelStacked="true" class="xl" required="true" [submitted]="submitted"
        [labelCaption]="'ui.reason'|translate">
         <jbm-input-error [formControl]="formControls.reason" [submitted]="submitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-textarea>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <button type="button" class="btn btn-sm btn-primary" (click)="save()">{{'production.drop-receipt'|translate}}</button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="close()"></jbm-cancel-button>
      </div>
   </div>
</form>
