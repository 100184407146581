import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RecipeArticlesService} from "../../data/recipe_articles.service";
import {SanitizationService} from "../../../../services/sanitization/sanitization.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'recipe-articles',
  templateUrl: './recipe-articles.component.html',
  styleUrls: ['./recipe-articles.component.scss']
})
export class RecipeArticlesComponent implements OnInit {
  articles: any[]=[];
  articles_list: any[]=[];
  article_options: JBMSelectOption[]=[];
  article_id: number=0;
  amount: number=0.0;
  language: string=this.TokenService.getLanguage();

  constructor(
      private RecipeArticlesService: RecipeArticlesService,
      private SanitizationService: SanitizationService,
      private TokenService: TokenService
  ) {
    this.recipe={ id: 0, description:'' }
  }

  @Input() recipe: any={};

  @Output() onClose=new EventEmitter();
  @Output() onSave=new EventEmitter();

  ngOnInit(): void {
    this.RecipeArticlesService.getArticlesList(this.language).subscribe(
        (data)=>this.articles_list=data.data,
        (error)=>console.error(error),
        ()=>
          this.RecipeArticlesService.get(this.recipe.public_id).subscribe(
              (data)=>this.articles=data.data,
              (error)=>console.error(error),
              ()=>{
                this.updateArticleOptions();
                this.formatArticles();
              }
          )
    )
  }
  updateArticleOptions() {
    this.article_options=[];
    for(let article of this.articles_list)
      if(this.articleIndex(parseInt(article.key))===-1)
        this.article_options.push({ key: article.key, value: article.value });
  }
  articleIndex(id: number) {
    for(let a=0; a<this.articles.length; a++)
      if(this.articles[a].articles_id===id) return a;
    return -1;
  }
  formatArticles() {
    for(let article of this.articles) {
      article.amountStr = article.amount.toString().replace('.', ',');
      article.description = this.getArticleDescription(article.articles_id.toString());
    }
  }
  changeArticle(event) {
    this.article_id=parseInt(event.target.value);
  }
  getArticleDescription(key: string) {
    for(let item of this.articles_list)
      if(item.key===key)
        return item.value;
    return '';
  }
  // Amount
  keyupAmount(event) {
    this.amount=parseFloat(event.target.value);
    if(isNaN(this.amount))
      this.amount=0;
  }
  blurAmount(id: number, event) {
    let amount=event.target.value;
    if(isNaN(parseFloat(amount))) {
      amount = '0';
      (<HTMLInputElement>document.getElementById('amount-'+id.toString())).value = '0';
    }
    if(amount==='0')
        this.delete(id);
  }
  // Actions
  add() {
    this.articles.push( {
      id: 0,
      articles_id: this.article_id,
      description: this.getArticleDescription(this.article_id.toString()),
      amount: this.amount,
      amountStr: this.amount.toString().replace('.',',')
    });

    // Reset inputs
    this.article_id=0;
    this.amount=0;
    (<HTMLInputElement>document.getElementById('article')).value = '';
    (<HTMLInputElement>document.getElementById('amount')).value = '';

    this.updateArticleOptions();
  }
  delete(id: number) {
    for(let a=0; a<this.articles.length; a++)
      if(this.articles[a].id===id)
        this.articles.splice(a,1);

    this.updateArticleOptions();
  }
  getArticlesData() {
    let data={ recipes_id: this.recipe.id, articles: [] }
    for(let article of this.articles) {
      data.articles.push( { articles_id: article.articles_id, amount: article.amount })
    }
    return data;
  }
  save() {
    let data=this.getArticlesData();
    this.RecipeArticlesService.update(data).subscribe(
        ()=>{},(error)=>console.error(error),()=>this.onSave.emit())
  }
  close() {
    this.onClose.emit();
  }
}
