import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, RequiredValidator, Validators} from '@angular/forms';
import {ProductionReceiptsService} from '../data/production-receipts.service';

@Component({
  selector: 'drop-receipt',
  templateUrl: './drop-receipt.component.html',
  styleUrls: ['./drop-receipt.component.scss']
})
export class DropReceiptComponent implements OnInit {
  form: FormGroup;
  submitted: boolean=false;

  constructor(
      private formBuilder: FormBuilder,
      private ProductionReceiptsService: ProductionReceiptsService
  ) { }

  @Input() id: number;
  @Input() receipt_id: number;

  @Output() onClose=new EventEmitter();
  @Output() onSuccess=new EventEmitter();

  ngOnInit(): void {
    this.addControls();
    setTimeout(()=>document.getElementById('reason').focus(),500);
  }
  addControls() {
    this.form = this.formBuilder.group(
      {
        reason: new FormControl('',{validators: [Validators.required], updateOn: 'blur'})
      }
    );
  }
  get formControls() {
    return this.form.controls;
  }
  save() {
    this.submitted = true;
    if(this.form.invalid)
      return;

    let data={
      id: this.id,
      reason: this.formControls.reason.value
    }
    this.ProductionReceiptsService.dropReceipt(data).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=>this.onSuccess.emit()
    )
  }
  close() {
    this.onClose.emit();
  }
}
